import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '@app/core/services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class QuotationAPI {
    private apiUrl = '';

    listPageSize = 10;
    listPageIndex = 0;
    selectedProps: any;
    apiParameter: any;

    constructor(public sharedServices: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedServices.getAPIUrl();
    }

    /*************************************************************************
    Purpose: Get all epc documents with their Energy power consumption details
    ***************************************************************************/
    getAllQuotation(filterCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        let body = JSON.stringify(filterCriteria)
        return this.http.post(this.apiUrl + '/api/Quotation/GetAllQuotations', body, options);
    }

    /*************************************************************************
    Purpose: add new epc details
    Parameters: epc details from form
    ***************************************************************************/
    addQuotation(quoteObject) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(quoteObject);
        return this.http.post(this.apiUrl + '/api/Quotation/AddQuotation', body, options);
    }


    /*************************************************************************
Purpose: add new epc details
Parameters: epc details from form
***************************************************************************/
    updateQuotation(quoteObject) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(quoteObject);
        return this.http.post(this.apiUrl + '/api/Quotation/UpdateQuotation', body, options);
    }

    /*****************************************************************
    getPropertyById()
    purpose : This property get property details to updat epc rating (only name require to update from this api)
    *******************************************************************/
    getQuotationById(quoteUID: string): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Quotation/Get/' + quoteUID);
    }

    /*****************************************************************
     UploadPermitDocuments()
     param:  Document to be uploaded
     purpose : this method will upload the selected Document to server.
     *******************************************************************/
    uploadQuotationDocuments(file): Observable<string> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<string>(this.apiUrl + '/api/PermitToWork/upload', formData);
    }

    /*****************************************************************
    deleteUploadedFile()
    purpose : this method will delete uploaded parcel image.
    Parameter: file name
    *******************************************************************/
    deleteUploadedFile(image) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post(this.apiUrl + '/api/PermitToWork/DeleteUploadedFile?fileName=' + image, option);
    }

    /*****************************************************************
         getIssue()
         param: issue id
         purpose : this method will get the issue by id
    *******************************************************************/
    getIssue(uId: string) {
        return this.http.get(this.apiUrl + '/api/Issues/Get/' + uId);
    }

    /*****************************************************************
    GetAllCurrentIssuelist()
    purpose : this method will get all issues from API
    *******************************************************************/
    GetAllCurrentIssuelist(issueFilterCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(issueFilterCriteria);
        return this.http.post(this.apiUrl + '/api/Issues/getAll', body, options);
    }

    /*****************************************************************
    getAllSupplier()
    purpose : this method will get all properties for current user
    *******************************************************************/
    getAllSupplier(data) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        let body = JSON.stringify(data);
        return this.http.post(this.apiUrl + '/api/Vendors/GetAll', body, options);
    }



    /*****************************************************************
    AcceptOrRejectQuote()
    param:  Quote model
    purpose : this method will Approve Or RejectQuote.
    *******************************************************************/
   AcceptOrRejectQuote(model) {
        const options = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(model);
        return this.http.post(this.apiUrl + '/api/Quotation/AcceptOrRejectQuote', body, options);
    }





    /*****************************************************************
    deleteQuotes()
    param:  Quotes to be deleted
    purpose : this method will delete existing quote.
    *******************************************************************/
    deleteQuotes(id: string, version: any) {
        return this.http.delete(this.apiUrl + '/api/Quotation/DeleteQuotation/' + id + '?version=' + version);
    }

    /*****************************************************************
  SelectVendorForQuotation()
  param:  Quote model
  purpose : this method will Approve Or RejectQuote.
  *******************************************************************/
    SelectVendorForQuotation(model) {
        const options = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(model);
        return this.http.post(this.apiUrl + '/api/Quotation/SelectVendorForQuotation', body, options);
    }


    GetQuotesChartData(obj) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        let body = JSON.stringify(obj)
        return this.http.post(this.apiUrl + '/api/Quotation/GetQuotesChartData', body, options);
    }

    /*****************************************************************
   downloadQuoteAsExcel()
   param:  filter criteria json
   purpose : this method will filter all Quotes based on criteria and download it.
   *******************************************************************/
    downloadQuoteAsExcel(dcriteria) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = JSON.stringify(dcriteria);
        return this.http.post(this.apiUrl + '/api/Quotation/ExportAllQuotation', body, { observe: 'response', responseType: 'blob', headers: headers });
    }
}