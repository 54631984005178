export class Events {
    id: string;
    uId: string;
    dType: string;
    propertyUId: string;
    type: number;
    startDate: Date;
    endDate: Date;
    title: string;
    description: string;
    location: string;
    resourceUId: string;
    hosts: string;
    ownerOrgUId: string;
    source: string;
    active: boolean;
    archieved: boolean;
    version: number;
    propertyName: string;
    createdBy: string;
    updatedBy: string;
    isFullDay: boolean;
    propertyUnitTenantUId: string;
    isForMultipleProperties: boolean;
    multiplePropertyUIds: [];
    propertyManagerName: string;
    propertyManagerUId: string;
    tenantName: string;
    createdOn: Date;
    updatedOn: Date;
    participants: Participants[];
    attachments = [];
}
export class Participants {
    userUId: string;
    name: string;
    email: string;
    checkedInTime: Date;
    cardNumber: string;
    checkedIn: false;
    checkedOut: false;
    checkedOutTime: Date;
    type: string;
    ramsReviewRequired: boolean;
    ramsReviewed: boolean;
} 
export class Property {
    uId: string;
    name: string;
}
export class Tenant {
    uId: string;
    name: string;
}